import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  ThunkDispatch,
} from '@reduxjs/toolkit';

import { NONE_VALUE, sourceListType } from 'core/constants/app-constants';
import {
  getCurrentTerm,
  getTermByRange,
} from 'core/services/dpl/dpl-plan-term-service';
import {
  createPlanCourse,
  deletePlanCourse,
  updatePlanCourses,
} from 'core/services/idp/plan-course-service';
import { getStudentProfile } from 'core/services/idp/student-profile-service';
import {
  preProcessPlanForUI,
  sortPlanByDate,
} from 'core/services/ui/plan-ui-data-service';
import { scrollToClassCard, scrollToMovedClass } from 'core/utils/html-utils';
import { cloneDraftObject } from 'core/utils/structured-clone-utils';
import {
  HttpQueueWorkerManager,
  OnMessageData,
} from 'core/workers/http-queue-worker';
import {
  PollerTimeOutError,
  startPlanPoller,
} from '../../services/idp/plan-poller-service';
import {
  copyPlan,
  deletePlan,
  getActivePlan,
  getPlanList,
  resetPlan,
  updatePlanVersion,
} from '../../services/idp/plan-service';
import { setPlanSetup } from './planSetupSlice';
import {
  dispatchThunkError as dispatchThunkErrorUtil,
  pickEmplid,
} from './utils';

const dispatchThunkError = (
  ...args: Parameters<typeof dispatchThunkErrorUtil>
) => {
  const [dispatch, error, thunkFunction] = args;
  dispatchThunkErrorUtil(...args);
  // ===========================================
  const res = error as HTTPError;
  const statusCode = res.response?.status || res.status || null;
  if (statusCode) {
    dispatch(setHttpStatusCode(statusCode));
  }
  // ===========================================
  if (error instanceof PollerTimeOutError) {
    dispatch(setPlanStatus('poller-timeout'));
  } else if (thunkFunction.typePrefix === getPlanAsync.typePrefix) {
    dispatch(setPlanStatus('loading-failed'));
  } else {
    dispatch(setPlanStatus('failed'));
  }
  // ===========================================
};

const scrollContainerId = 'degree-plan';

const planWorker = new HttpQueueWorkerManager({ logMessage: true });

const initialState: PlanState = {
  isDefaultState: true,
  httpStatusCode: null,
  status: 'pending',
  planListLoading: false,
  planListLoaded: false,
  lastConsumerEmplid: null,
  currentTerm: null,
  termMap: null,
  dataModified: false,
  dataList: [],
  data: {
    uuid: '',
    currentPlanVersion: 0,
    maxPlanVersion: 0,
    catalogYear: '',
    degreeProgram: '',
    planName: '',
    lastModified: null,
    configuration: {
      method: 'graduation-term',
      graduationTerm: '',
      creditHourTerm: undefined,
      includeSummerTerms: false,
    },
    summary: {
      academicPlan: NONE_VALUE,
      academicPlanCode: NONE_VALUE,
      catalogYearDescription: NONE_VALUE,
      cumulativeGpa: '',
      location: NONE_VALUE,
      college: NONE_VALUE,
      targetGraduationTerm: '',
      generalStudiesCurriculumCode: 'M',
      degree: NONE_VALUE,
      requirementSummaries: [],
    },
    transferCredits: {
      courseCredits: [],
      testCredits: [],
    },
    studentProgress: [],
    degreePlan: [],
    validationErrors: {
      termCode: '',
      sessionCode: undefined,
      subject: undefined,
      catalogNumber: undefined,
      validationError: [],
      reason: 'GENERIC',
      maxCreditExceed: undefined,
    },
    buildStatus: 'REQUESTING_DEGREE_AUDIT',
  },
  dataHistory: {
    undoStack: [],
    redoStack: [],
    undoStackActive: false,
    redoStackActive: false,
    lastChangeType: null,
  },
  dataPlanOwner: null,
  warningDnD: {
    hasWarning: false,
    message: undefined,
  },
  replaceCourse: null,
};

export const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    setReplaceCourse: (
      state,
      { payload }: ActionOf<API.PlanData.ClassDeleteUrlParams | null>,
    ) => {
      state.replaceCourse = payload;
    },
    setWarningDnD: (state, { payload }: ActionOf<WarningDnD>) => {
      state.warningDnD = payload;
    },
    setCurrentTerm: (state, { payload }: ActionOf<DPL_API.TermData.Term>) => {
      state.currentTerm = payload;
    },
    setTermMap: (
      state,
      { payload }: ActionOf<DPL_API.TermData.TermMap | null>,
    ) => {
      state.termMap = payload;
    },
    resetPlanFailFlags: (state) => {
      state.status = 'ready';
      state.httpStatusCode = null;
    },
    setHttpStatusCode: (state, { payload }: ActionOf<number | null>) => {
      state.httpStatusCode = payload;
    },
    setPlanListLoadFlags: (state, action: ActionOf<boolean>) => {
      state.planListLoading = action.payload;
      state.planListLoaded = !action.payload;
    },
    setPlanListLoading: (state, action: ActionOf<boolean>) => {
      state.planListLoading = action.payload;
    },
    setPlanListLoaded: (state, action: ActionOf<boolean>) => {
      state.planListLoaded = action.payload;
    },
    setPlanStatus: (state, action: ActionOf<PlanStatus>) => {
      state.status = action.payload;
    },
    setLastConsumerEmplid: (state, action: ActionOf<string>) => {
      state.lastConsumerEmplid = action.payload;
    },
    setPlanName: (state, action: ActionOf<string>) => {
      state.data.planName = action.payload;
    },
    setPlanDegree: (state, action: ActionOf<API.PlanData.Term[]>) => {
      state.data.degreePlan = action.payload;
    },
    setPlanToDefault: (state) => {
      state.status = 'ready';
      state.isDefaultState = true;
      state.data = initialState.data;
    },
    setPlan: (state, { payload: rawPlan }: ActionOf<API.PlanData.Plan>) => {
      const { currentTerm, termMap } = state;
      const planData = preProcessPlanForUI({
        rawPlan,
        termMap,
        currentTerm,
      })!;

      state.data = planData;
      state.isDefaultState = false;
    },
    setPlanList: (state, action: ActionOf<API.PlanData.PlanRecord[]>) => {
      state.dataList = action.payload;
    },
    setPlanOwner: (state, action: ActionOf<API.StudentData.Profile>) => {
      state.dataPlanOwner = action.payload;
    },
    moveCourseUI: (state, { payload }: ActionOf<ClassDragItemData>) => {
      const { source, target } = payload;
      const planTerms = state.data.degreePlan;
      const SOURCE_LIST_TYPE = sourceListType[source.classType] || 'classes';
      //===========================================================
      // source
      //===========================================================
      const sourceTerm = planTerms.find((term) => term._uid === source.termUId);
      const sourceSession = sourceTerm?.sessions.find(
        (sessions) => sessions._uid === source.sessionUId,
      )!;
      const sourceClasses = sourceSession?.[SOURCE_LIST_TYPE] || [];

      const classItem = sourceClasses.find(
        (sClass) => sClass._uid === source.classUId,
      );

      //===========================================================
      if (!classItem) return;
      //===========================================================

      // remove source class item
      sourceSession[SOURCE_LIST_TYPE] = sourceClasses.filter(
        (sClass) => sClass._uid !== source.classUId,
      );
      //===========================================================
      // target
      //===========================================================
      const targetTerm = planTerms.find((term) => term._uid === target.termUId);
      const targetSession = targetTerm?.sessions.find(
        (session) => session._uid === target.sessionUId,
      )!;

      // add class item to target
      classItem._uiMetaData = {
        ...classItem._uiMetaData!,
        termUId: target.termUId,
        sessionUId: target.sessionUId,
        saving: true,
      };
      targetSession[SOURCE_LIST_TYPE]?.push(classItem);
      //===========================================================
      state.dataModified = true;
    },
    addCourseUI: (
      state,
      {
        payload,
      }: ActionOf<{
        cardId: string;
        termCode: string;
        sessionCode: string;
        subject: string;
        catalogNumber: string;
        title: string;
        creditHours: number;
      }>,
    ) => {
      const { cardId, subject, catalogNumber, title, sessionCode, termCode } =
        payload;

      const targetTerm = state.data.degreePlan.find(
        (term) => term.term === termCode,
      );
      const targetSession = targetTerm?.sessions.find(
        (session) => session.sessionName === sessionCode,
      )!;

      targetSession.selectedClasses.push({
        _uid: cardId,
        subject,
        catalogNumber,
        title,
        sessionCode,
        satisfyRequirement: undefined,
        creditHours: 0,
        minCreditHours: 0,
        maxCreditHours: 0,
        courseLabel: undefined,
        _uiMetaData: {
          cardId,
          termUId: targetTerm?._uid!,
          sessionUId: targetSession._uid!,
          saving: true,
          classType: 'selected',
          sessionPending: false,
          isTermInprogress: false,
          creditSource: 'course-credit',
          creditHoursForDisplay: '',
          fulfilledRequirementNames: [],
          termCode: '',
          sessionCode: '',
          warning: null,
          classUId: '',
        },
      });
      //===========================================================
      state.dataModified = true;
    },
    removeCourseUI: (
      state,
      { payload }: ActionOf<API.PlanData.ClassUIMetaData>,
    ) => {
      const { sessionCode, termCode, classType, classUId } = payload;

      const targetTerm = state.data.degreePlan.find(
        (term) => term.term === termCode,
      );
      const targetSession = targetTerm?.sessions.find(
        (session) => session.sessionName === sessionCode,
      )!;

      const SOURCE_LIST_TYPE = sourceListType[classType] || 'classes';
      const sourceClasses = targetSession[SOURCE_LIST_TYPE] || [];

      const targetCard = sourceClasses.find(
        (sClass) => sClass._uid === classUId,
      );

      if (targetCard) {
        targetCard._uiMetaData!.saving = true;
      }
    },
    resetPlanHistory: (state) => {
      state.dataHistory = initialState.dataHistory;
    },
    revertPlanChanges: (
      state,
      { payload: { direction } }: ActionOf<{ direction: HistoryDirection }>,
    ) => {
      // //===========================================================
      const { lastHistoryItem, pastStack, futureStack, undoStack, redoStack } =
        processHistoryStacks(state.dataHistory, direction);
      //===========================================================
      if (!lastHistoryItem) {
        return;
      }
      //===========================================================
      const oldPlanSnapshot = state.data;
      const { changeType, currentPlanVersion } = lastHistoryItem;

      pastStack.pop();

      const historyItem = {
        changeType,
        currentPlanVersion,
        planSnapshot: oldPlanSnapshot,
      } as PlanHistoryItem;

      if (changeType === 'CLASS_MOVE') {
        const { classChange } = lastHistoryItem;
        historyItem.classChange = classChange;
        scrollToClassCard(classChange.source.classUId);
      } else if (changeType === 'CLASS_ADD') {
        const { classChange } = lastHistoryItem;
        historyItem.classChange = classChange;
      } else if (changeType === 'SETUP_UPDATE') {
        const { setupChange } = lastHistoryItem;

        historyItem.setupChange = {
          newSetup: setupChange.oldSetup,
          oldSetup: setupChange.newSetup,
        };
      }
      futureStack.push(historyItem);
      //===========================================================
      state.dataHistory.undoStackActive = undoStack.length > 0;
      state.dataHistory.redoStackActive = redoStack.length > 0;
      //===========================================================
    },
    trackPlanChanges: (
      state,
      {
        payload: { changeType, setupChange, classChange },
      }: ActionOf<PlanHistoryInput>,
    ) => {
      state.dataHistory.undoStackActive = true;
      state.dataHistory.lastChangeType = changeType;
      //===========================================================
      const planSnapshot = cloneDraftObject<API.PlanData.Plan>(state.data);
      const { undoStack, redoStack } = state.dataHistory;
      const { currentPlanVersion } = state.data;

      const historyItem = {
        changeType,
        currentPlanVersion,
        planSnapshot,
      } as PlanHistoryItem;

      if (changeType === 'SETUP_UPDATE') {
        historyItem.setupChange = setupChange;
      } else {
        historyItem.classChange = classChange;
      }
      //===========================================================
      // if save a change after click UNDO, reset following history
      //===========================================================
      if (redoStack.length > 0) {
        state.dataHistory.redoStack = [];
        state.dataHistory.redoStackActive = false;
      }
      //===========================================================
      // if reach the MAX_CHANGES remove the oldest history snapshot
      // to make space to the new newest one
      //===========================================================
      const MAX_CHANGES = 20;
      if (undoStack.length === MAX_CHANGES) {
        undoStack.shift();
      }
      //===========================================================
      undoStack.push(historyItem);

      state.dataHistory.undoStackActive = undoStack.length > 0;
    },
  },
});

const processHistoryStacks = (
  dataHistory: DataHistory,
  direction: HistoryDirection,
) => {
  const { undoStack, redoStack } = dataHistory;
  let pastStack: PlanHistoryItem[], futureStack: PlanHistoryItem[];

  const isUndo = direction === 'undo';

  if (isUndo) {
    pastStack = undoStack;
    futureStack = redoStack;
  } else {
    pastStack = redoStack;
    futureStack = undoStack;
  }

  const lastHistoryItem = pastStack[pastStack.length - 1];

  return {
    isUndo,
    pastStack,
    futureStack,
    undoStack,
    redoStack,
    lastHistoryItem,
  };
};

function setPlanFromPoller(
  emplid: string,
  planId: string,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  thunkFunction: { typePrefix: string },
): Promise<void> {
  return startPlanPoller({
    emplid,
    planId,
    onSuccess: async ({ setup, plan }) => {
      const termMap =
        plan.degreePlan.length > 0
          ? await getTermByRange([
              'curr',
              plan.degreePlan[plan.degreePlan.length - 1].term,
            ])
          : null;

      dispatch(setLastConsumerEmplid(emplid));
      dispatch(setTermMap(termMap));
      dispatch(setPlan(plan));
      dispatch(setPlanSetup(setup!));
      dispatch(setPlanStatus('ready'));
    },
    onError: async (error) => {
      dispatchThunkError(dispatch, error, thunkFunction);
    },
  });
}

export const revertPlanChangesAsync = createAsyncThunk(
  'planSlice/revertPlanChangesAsync',
  async (
    payload: {
      planId: string;
      direction: HistoryDirection;
    },
    { dispatch, getState },
  ) => {
    dispatch(resetPlanFailFlags());
    dispatch(setPlanStatus('loading'));
    try {
      const { planId, direction } = payload;
      const emplid = pickEmplid(getState());

      await updatePlanVersion(emplid, planId, direction);
      await setPlanFromPoller(emplid, planId, dispatch, revertPlanChangesAsync);

      dispatch(
        revertPlanChanges({
          direction,
        }),
      );

      return true;
    } catch (error) {
      dispatchThunkError(dispatch, error, revertPlanChangesAsync);
      return false;
    }
  },
);

export const getPlanListAsync = createAsyncThunk(
  'planSlice/getPlanListAsync',
  async (
    param:
      | {
          searchEmplId?: string;
        }
      | undefined,
    { dispatch, getState, signal },
  ) => {
    dispatch(setPlanListLoadFlags(true));
    dispatch(setPlanList([]));
    try {
      const emplid = param?.searchEmplId || pickEmplid(getState());
      const dataList = await getPlanList(emplid, signal);
      const sortedList = sortPlanByDate(dataList);

      dispatch(setPlanList(sortedList));
      dispatch(setPlanListLoadFlags(false));
      return true;
    } catch (error) {
      dispatch(setPlanListLoading(false));
      dispatchThunkError(dispatch, error, getPlanListAsync);
    }
  },
);

export const getPlanListSilentlyAsync = createAsyncThunk(
  'planSlice/getPlanListSilentlyAsync',
  async (_payload, { dispatch, getState, signal }) => {
    try {
      const emplid = pickEmplid(getState());
      const dataList = await getPlanList(emplid, signal);
      const sortedList = sortPlanByDate(dataList);

      dispatch(setPlanList(sortedList));

      return {
        success: true,
        fetchedPlanList: sortedList,
      };
    } catch (error) {
      dispatchThunkError(dispatch, error, getPlanListSilentlyAsync);
      return {
        success: false,
        fetchedPlanList: [],
      };
    }
  },
);

export const getPlanAsync = createAsyncThunk(
  'planSlice/getPlanAsync',
  async (
    payload: {
      planId: string;
      searchEmplId?: string;
    },
    { dispatch, getState },
  ) => {
    dispatch(resetPlanFailFlags());
    dispatch(setPlanStatus('loading'));

    try {
      const { planId, searchEmplId } = payload;
      const emplid = searchEmplId || pickEmplid(getState());

      if (!planId) {
        dispatch(setPlanStatus('loading-failed'));
        return;
      }

      if (searchEmplId) {
        const planOwner = await getStudentProfile(searchEmplId);
        dispatch(setPlanOwner(planOwner));
      }

      if (planId === 'active') {
        const activePlan = await getActivePlan(emplid);
        const activePlanId = activePlan.uuid;
        await setPlanFromPoller(emplid, activePlanId, dispatch, getPlanAsync);
      } else {
        await setPlanFromPoller(emplid, planId, dispatch, getPlanAsync);
      }
    } catch (error) {
      dispatchThunkError(dispatch, error, getPlanAsync);
    }
  },
);

export const addCourseAsync = createAsyncThunk(
  'planSlice/addCourseAsync',
  async (
    {
      planId,
      data,
    }: {
      planId: string;
      data: API.PlanData.ClassCreatePayload;
    },
    { dispatch, getState },
  ) => {
    try {
      dispatch(setHttpStatusCode(null));
      dispatch(setPlanStatus('saving'));

      const emplid = pickEmplid(getState());
      // =====================================
      // Replace course
      // =====================================
      const state = getState() as AppState;
      const replaceCourse = state.plan.replaceCourse;
      if (replaceCourse) {
        await deletePlanCourse(emplid, planId, replaceCourse);
        dispatch(setReplaceCourse(null));
      }
      // =====================================
      await createPlanCourse(emplid, planId, data);
      await setPlanFromPoller(emplid, planId, dispatch, getPlanAsync);

      return true;
    } catch (error) {
      dispatchThunkError(dispatch, error, addCourseAsync);
      return false;
    }
  },
);

export const deleteCourseAsync = createAsyncThunk(
  'planSlice/deleteCourseAsync',
  async (
    {
      planId,
      data,
    }: {
      planId: string;
      data: API.PlanData.ClassDeleteUrlParams;
    },
    { dispatch, getState },
  ) => {
    try {
      dispatch(setHttpStatusCode(null));
      dispatch(setPlanStatus('saving'));

      const emplid = pickEmplid(getState());
      await deletePlanCourse(emplid, planId, data);
      await setPlanFromPoller(emplid, planId, dispatch, getPlanAsync);

      return true;
    } catch (error) {
      dispatchThunkError(dispatch, error, deleteCourseAsync);
      return false;
    }
  },
);

export const movePlanClassAsync = createAsyncThunk(
  'planSlice/movePlanClassAsync',
  async (
    payload: {
      planId: string;
      dragItem: ClassDragItemData;
    },
    { dispatch, getState },
  ) => {
    dispatch(setPlanStatus('saving'));

    const { planId, dragItem } = payload;
    const emplid = pickEmplid(getState());

    try {
      planWorker.addSharedEventListener(
        'message',
        async (evt: MessageEvent<OnMessageData>) => {
          if (evt.data.action === 'success') {
            const dragItem = evt.data.payload.request.tags
              .dragItem as ClassDragItemData;

            dispatch(
              trackPlanChanges({
                changeType: 'CLASS_MOVE',
                classChange: dragItem,
              }),
            );

            const { isQueueEmpty } = evt.data;

            if (isQueueEmpty) {
              planWorker.removeAllEventListener('message');

              dispatch(setPlanStatus('saved'));
              setTimeout(() => dispatch(setPlanStatus('ready')), 2_000);

              await setPlanFromPoller(
                emplid,
                planId,
                dispatch,
                movePlanClassAsync,
              );

              scrollToMovedClass({
                termCode: dragItem.target.termCode,
                sessionCode: dragItem.target.sessionCode,
                cardId: dragItem.source.cardId,
              });
            }
          } else if (evt.data.action === 'error') {
            planWorker.removeAllEventListener('message');

            const error = evt.data.payload;
            dispatch(setPlanStatus('save-failed'));
            dispatchThunkError(dispatch, error, movePlanClassAsync);
            setPlanFromPoller(emplid, planId, dispatch, movePlanClassAsync);
          }
        },
      );

      updatePlanCourses(emplid, planId, dragItem, planWorker);
    } catch (error) {
      dispatchThunkError(dispatch, error, movePlanClassAsync);
    }
  },
);

export const deletePlanAsync = createAsyncThunk(
  'planSlice/deletePlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    try {
      const emplid = pickEmplid(getState());
      const res = await deletePlan(emplid, planId);

      return res;
    } catch (error) {
      dispatchThunkError(dispatch, error, deletePlanAsync);
      return false;
    }
  },
);

export const copyPlanAsync = createAsyncThunk(
  'planSlice/copyPlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    try {
      const emplid = pickEmplid(getState());
      const copiedPlanId = await copyPlan(emplid, planId);

      return copiedPlanId;
    } catch (error) {
      dispatchThunkError(dispatch, error, copyPlanAsync);
      return null;
    }
  },
);

export const resetPlanAsync = createAsyncThunk(
  'planSlice/resetPlanAsync',
  async ({ planId }: { planId: string }, { dispatch, getState }) => {
    try {
      dispatch(resetPlanFailFlags());

      const emplid = pickEmplid(getState());
      await resetPlan(emplid, planId);
      await setPlanFromPoller(emplid, planId, dispatch, resetPlanAsync);

      return true;
    } catch (error) {
      dispatchThunkError(dispatch, error, resetPlanAsync);
      return false;
    }
  },
);

export const getCurrentTermAsync = createAsyncThunk(
  'planSlice/getCurrentTermAsync',
  async (_, { dispatch }) => {
    try {
      const currentTerm = await getCurrentTerm();
      dispatch(setCurrentTerm(currentTerm));
    } catch (error) {
      dispatchThunkError(dispatch, error, getCurrentTermAsync);
    }
  },
);

export const getDefaultPlanData = () => {
  return {
    ...initialState.data,
  };
};

export const {
  resetPlanFailFlags,
  setHttpStatusCode,
  setPlanListLoading,
  setPlanListLoaded,
  setPlanListLoadFlags,
  setPlanOwner,
  setPlanName,
  setPlanToDefault,
  setPlanDegree,
  setPlan,
  setPlanList,
  setPlanStatus,
  addCourseUI,
  moveCourseUI,
  removeCourseUI,
  revertPlanChanges,
  resetPlanHistory,
  trackPlanChanges,
  setLastConsumerEmplid,
  setCurrentTerm,
  setTermMap,
  setWarningDnD,
  setReplaceCourse,
} = planSlice.actions;

export { scrollContainerId };

export default planSlice.reducer;
